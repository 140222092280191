// Set file variable
$filename: "scss/custom/_banner.scss";

/*==============================================================================
  @Banner - #{$filename}
==============================================================================*/

/**
 * Structure
 */

.banner {
  position: relative;
  overflow: hidden;
  background: $bg-header;
  background: var(--bg-header, $bg-header);
}

.banner::before {
  content: "";
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: rgba($black, 0.1);
}

.banner::after {
  background: $bg-main;
  background: var(--bg-main, $bg-main);
  bottom: 0;
  content: "";
  display: block;
  height: 70%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: skewY(-8deg);
  transform-origin: 100%;
  z-index: auto;
}

.banner .container {
  position: relative;
  overflow: hidden;
  padding: 0;
}

.banner-inner {
  position: relative;
  overflow: hidden;
  z-index: 5;
  padding: 1em;
  background: $bg-banner;
  background: var(--bg-banner, $bg-banner);
}

.banner-full .banner-inner {
  background: $bg-banner-full;
  background: var(--bg-banner-full, $bg-banner-full);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $bg-banner-full;
    background: var(--bg-banner-full, $bg-banner-full);
    opacity: 0.6;
    z-index: 5;
  }
}

.banner-full .container {
  position: static;
  z-index: 10;
}

@include media-min("medium") {
  .banner::before {
    display: none;
  }

  .banner .container {
    padding: map-get($grid, "container-padding");
  }

  .banner .banner-inner {
    border-radius: 4px;
  }

  // Full
  .banner-full .banner-inner {
    padding: 4em 3em;
  }

  // Not full
  .banner:not(.banner-full) .banner-inner {
    padding: 0;
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
  }

  .banner:not(.banner-full) .banner-content {
    padding: 2em;
    flex: 0 0 55%;
  }

  .banner:not(.banner-full) .banner-images {
    flex: 0 0 45%;
  }
}

/**
 * Banner Content
 */

.banner-content {
  position: relative;
  z-index: 5;
  margin: 0 auto;
  text-align: center;

  h1 {
    font-weight: font-weight("light");
  }
}

@include media-min("medium") {
  .banner-content {
    text-align: left;

    h1 {
      font-size: 2.5em;
    }
  }

  .banner-full .banner-content {
    max-width: 700px;
    float: none;
    text-align: center;
  }
}

@include media-min("large") {
  .banner-content h1 {
    font-size: 3em;
  }
}

/**
 * Banner Actions
 */

.banner-action {
  margin: 1em 0;

  .button,
  .phone {
    display: block;
    margin: 0 0 1em 0;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .phone {
    font-size: 0.9rem;
    text-transform: uppercase;
    color: $color-light;

    .number {
      display: block;
      font-size: 1.2rem;
      color: $color;
    }
  }
}

@include media-min("medium") {
  .banner-action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 2em 0 1em;

    .button,
    .phone {
      display: inline-block;
      margin: 0 1em 0 0;
      text-align: left;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .banner-full .banner-action {
    justify-content: center;
  }
}

/* Text Light */
.banner.text-light {
  color: $white;

  h1,
  .text-lead {
    color: $white;
  }

  .banner-action .phone {
    color: rgba($white, 0.75);
  }

  .banner-action .phone .number a {
    color: $white;
  }
}

/**
 * Banner Images
 */

.banner-images {
  display: none;

  img {
    margin: 0;
  }
}

.banner-full .banner-images {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: none;

  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include media-min("medium") {
  .banner-images {
    display: block;
  }
}
