// Set file variable
$filename: 'scss/custom/_buttons.scss';

/*==============================================================================
  @Buttons - #{$filename}
==============================================================================*/

.button {
  @include make-button();
  @include add-button-color((
    'background-deg' : 2%,
  ));
  // There are cases where we want button text to wrap
  white-space: normal;

  &.block {
    display: block;
  }
}

.button.default {
  @include add-button-color((
    'background' : rgba($black, 0.05),
    'hover' : (
      'background' : rgba($black, 0.1)
    )
  ));
}

.button.primary {
  border: 2px solid $color-button;
  color: $color-button;

  &:hover {
    color: $color-button;
  }

  &:active {
    color: $color-button;
  }

  @include add-button-color((
    'background' : transparent,
    'border' : 2px solid var(--color-button, var(--accent, $color-button)),
    'color' : var(--color-button, var(--accent, $color-button)),

    'hover' : (
      'background' : transparent,
      'border-color' : $color,
      'color' : var(--color-button, var(--accent, $color-button)),
    ),

    'active' : (
      'background' : transparent,
      'border-color' : $color,
      'color' : var(--color-button, var(--accent, $color-button)),
    ),
  ));
}

.button.outline {
  border: 2px solid $color-button;
  color: $color-button;

  &:hover {
    color: $color-button;
  }

  &:active {
    color: $color-button;
  }

  @include add-button-color((
    'background' : transparent,
    'border' : 2px solid var(--color-button, var(--accent, $color-button)),
    'color' : var(--color-button, var(--accent, $color-button)),

    'hover' : (
      'background' : transparent,
      'border-color' : $color,
      'color' : var(--color-button, var(--accent, $color-button)),
    ),

    'active' : (
      'background' : transparent,
      'border-color' : $color,
      'color' : var(--color-button, var(--accent, $color-button)),
    ),
  ));
}
